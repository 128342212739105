<template>
  <div>
    <!-- table -->
    <vue-good-table
      :columns="adaycols"
      :rows="applicants"
      :search-options="{
        enabled: true,
        placeholder: 'Aday Ara',}"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      theme="my-theme"
      @on-row-click="onDataGridItemClicked"
    >
      <div slot="emptystate">
        <center> Aday listesinde kayıt bulunmuyor.</center>
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Status -->
        <span v-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ status[`${props.row.status}`] }}
          </b-badge>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template>
              <b-button-group
                class="mt-10"
                size="sm"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  class="mr-1"
                  squared
                  variant="outline-info"
                  @click="onCvBtnClicked(props.row)"
                >
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-50"
                  />
                  <span>Cv Göster</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  class="mr-1"
                  squared
                  variant="outline-warning"
                  @click="onUpdateApplBtnClicked(props.row)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Düzenle</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  squared
                  variant="outline-danger"
                  @click="onDeleteApplBtnClicked(props.row)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Sil</span>
                </b-button>
              </b-button-group>
            </template>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              1 ila
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> {{ props.total }} kayıt gösteriliyor </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>
<script>
import * as apiApplicant from '@/api/islemler/applicant/applicantInfo'
import {
  BPagination, BFormSelect, BButtonGroup, BButton, VBModal, BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormSelect,
    BButtonGroup,
    BButton,
    BBadge,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      pageLength: 50,
      adaycols: [
        {
          label: 'Adı Soyadı',
          field: 'applicantName',
        },
        {
          label: 'E-posta',
          field: 'applicantEmail',
        },
        {
          label: 'Aday Telefonu',
          field: 'applicantPhone',
        },
        {
          label: 'Aday Durum',
          field: 'status',
        },
        {
          label: 'İşlem',
          field: 'action',
          sortable: false,
          width: '20%',
        },
      ],
      applicants: [],
      status: {
        1: 'Aktif',
        2: 'Pasif',
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        1: 'light-success',
        2: 'light-danger',
      }
      return status => statusColor[status]
    },
  },
  created() {
    this.getApplicantList()
  },
  mounted() {},
  methods: {
    getApplicantList() {
      apiApplicant.GetApplicantInfo().then(res => {
        this.applicants = res.data.list
      })
    },
    onDataGridItemClicked(params) {
      return params
    },
    onUpdateApplBtnClicked(row) {
      this.$router.push({ name: 'aday-kayit', params: { applicantId: row.applicantId } })
    },
    onDeleteApplBtnClicked(row) {
      this.$bvModal.msgBoxConfirm(` '${row.applicantName}' aday kaydı silinecek, onaylıyor musunuz?`, {
        title: 'Uyarı!',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'Evet',
        cancelTitle: 'Hayır',
        footerClass: 'p-2',
        hideHeaderClose: true,
        noCloseOnBackdrop: true,
        centered: true,
      })
        .then(value => {
          if (value) {
            apiApplicant.DeleteApplicant(row.applicantId).then(() => {
              this.getApplicantList()
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Kayıt Silindi',
                text: `${row.applicantName} aday kaydı silindi.`,
                icon: 'DeleteIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
    onCvBtnClicked(row) {
      this.$router.push({ name: 'aday-ozgecmis', params: { applicant: row } })
    },
  },
}
</script>
<style lang='scss'>
   @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
